import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import Section from './Section';

import './PlatformFeaturesSection.scss';

const PlatformFeaturesSection = (props) => {
  const { title, intro, features } = props;
  return (
    <Section className="PlatformFeaturesSection isolated-section text-center py-6">
      <Container>
        {title && <h2 className="mt-4 mx-auto">{title}</h2>}
        {intro && <p className="intro">{intro}</p>}

        <Row className="justify-content-center text-left mt-5">
          {features && features.map((feature, index) => (
            <Col key={index} lg={4} md={6} className="mb-4">
              <div className="feature-card">
                <div className="feature-card-image">
                  {feature.image &&
                    <img src={feature.image} className="mx-auto d-block"/>
                  }
                </div>
                <div className="feature-card-body p-4">
                  <div className="feature-card-body-title h3">{feature.title}</div>
                  <div className="feature-card-body-description">{feature.description}</div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
}

export default PlatformFeaturesSection